<template lang="">
    <div class="notice-detail">
        <div class="notice-detail__info">
            <div v-if="!idLoading">
                <h3 class="notice-detail__title">{{ noticeObject.message_title }}</h3>
                <p class="notice-detail__desc">
                    {{ noticeObject.message_content }}
                </p>
            </div>
            <Loading v-if="idLoading" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { mixinToasts } from '../../mixins';
import Loading from '../../components/Web/Loading/Loading.vue';

export default {
    mixins: [mixinToasts],
    components: {
        Loading,
    },
    data() {
        return {
            idLoading: false,
        };
    },
    async mounted() {
        await this.getNotification();
    },
    watch: {
        '$route.params': async function () {
            await this.getNotification();
        },
    },
    computed: {
        ...mapState('notificationWeb', ['noticeObject']),
    },
    methods: {
        ...mapActions('notificationWeb', ['getOneNoticeAction']),

        async getNotification() {
            this.idLoading = true;
            await this.getOneNoticeAction({
                id: this.$route.params.id,
                onSuccess: (isSuccess, data) => {
                    this.idLoading = false;
                    if (!isSuccess) this.showError(data);
                },
            });
        },
    },
};
</script>

<style lang="scss">
.notice-detail {
    width: 100%;
    max-width: 1170px;
    padding: 0 15px;
    margin: 40px auto;
}

.notice-detail__info {
    padding: 30px 20px;
    min-height: 500px;
    background-color: #fff;
    border-radius: 20px;
}

.notice-detail__title {
    margin-bottom: 12px;
    font-size: 26px;
    text-align: center;
}

.notice-detail__desc {
    font-size: 16px;
    white-space: pre-line;
}
</style>
